import"core-js/modules/es.array.includes.js";import"core-js/modules/es.string.includes.js";import"core-js/modules/es.string.trim.js";import escapeTextContentForBrowser from'escape-html';import z from'zod';import emojify from'soapbox/features/emoji';import{customEmojiSchema}from'./custom-emoji';import{relationshipSchema}from'./relationship';import{contentSchema,filteredArray,makeCustomEmojiMap}from'./utils';var avatarMissing=require('assets/images/avatar-missing.png');var headerMissing=require('assets/images/header-missing.png');var accountSchema=z.object({accepting_messages:z.boolean().catch(false),accepts_chat_messages:z.boolean().catch(false),acct:z.string().catch(''),avatar:z.string().catch(avatarMissing),avatar_static:z.string().catch(''),birthday:z.string().catch(''),bot:z.boolean().catch(false),chats_onboarded:z.boolean().catch(true),created_at:z.string().datetime().catch(new Date().toUTCString()),discoverable:z.boolean().catch(false),display_name:z.string().catch(''),emojis:filteredArray(customEmojiSchema),favicon:z.string().catch(''),fields:z.any(),// TODO
followers_count:z.number().catch(0),following_count:z.number().catch(0),fqn:z.string().catch(''),header:z.string().catch(headerMissing),header_static:z.string().catch(''),id:z.string(),last_status_at:z.string().catch(''),location:z.string().catch(''),locked:z.boolean().catch(false),moved:z.any(),// TODO
mute_expires_at:z.union([z.string(),z.null()]).catch(null),note:contentSchema,pleroma:z.any(),// TODO
source:z.any(),// TODO
statuses_count:z.number().catch(0),uri:z.string().url().catch(''),url:z.string().url().catch(''),username:z.string().catch(''),verified:z.boolean().default(false),website:z.string().catch(''),/*
   * Internal fields
   */display_name_html:z.string().catch(''),domain:z.string().catch(''),note_emojified:z.string().catch(''),relationship:relationshipSchema.nullable().catch(null),/*
   * Misc
   */other_settings:z.any()}).transform(function(account){var _account$pleroma,_account$other_settin,_account$pleroma2,_account$pleroma2$tag,_account$pleroma3,_account$other_settin2,_account$source,_account$source$plero,_account$pleroma4;var customEmojiMap=makeCustomEmojiMap(account.emojis);// Birthday
var birthday=((_account$pleroma=account.pleroma)===null||_account$pleroma===void 0?void 0:_account$pleroma.birthday)||((_account$other_settin=account.other_settings)===null||_account$other_settin===void 0?void 0:_account$other_settin.birthday);account.birthday=birthday;// Verified
var verified=account.verified===true||((_account$pleroma2=account.pleroma)===null||_account$pleroma2===void 0?void 0:(_account$pleroma2$tag=_account$pleroma2.tags)===null||_account$pleroma2$tag===void 0?void 0:_account$pleroma2$tag.includes('verified'));account.verified=verified;// Location
var location=account.location||((_account$pleroma3=account.pleroma)===null||_account$pleroma3===void 0?void 0:_account$pleroma3.location)||((_account$other_settin2=account.other_settings)===null||_account$other_settin2===void 0?void 0:_account$other_settin2.location);account.location=location;// Username
var acct=account.acct||'';var username=account.username||'';account.username=username||acct.split('@')[0];// Display Name
var displayName=account.display_name||'';account.display_name=displayName.trim().length===0?account.username:displayName;account.display_name_html=emojify(escapeTextContentForBrowser(displayName),customEmojiMap);// Discoverable
var discoverable=Boolean(account.discoverable||((_account$source=account.source)===null||_account$source===void 0?void 0:(_account$source$plero=_account$source.pleroma)===null||_account$source$plero===void 0?void 0:_account$source$plero.discoverable));account.discoverable=discoverable;// Message Acceptance
var acceptsChatMessages=Boolean(((_account$pleroma4=account.pleroma)===null||_account$pleroma4===void 0?void 0:_account$pleroma4.accepts_chat_messages)||(account===null||account===void 0?void 0:account.accepting_messages));account.accepts_chat_messages=acceptsChatMessages;// Notes
account.note_emojified=emojify(account.note,customEmojiMap);/*
   * Todo
   * - internal fields
   * - donor
   * - tags
   * - fields
   * - pleroma legacy fields
   * - emojification
   * - domain
   * - guessFqn
   * - fqn
   * - favicon
   * - staff fields
   * - birthday
   * - note
   */return account;});export{accountSchema};